<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>University</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-data-table :headers="headers" :items="atype_list" class="elevation-1" :search="search">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
          </v-text-field>
          <v-spacer></v-spacer>
          <span style="width: 20px"></span>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Add University</v-btn>
            </template>
            <v-card>
              <v-form v-model="valid">
                <v-card-title>
                  <v-row justify="space-between">
                    <span class="headline">{{ formTitle }}</span>
                    <v-btn icon dark @click="dialog = false">
                      <v-icon color="black">mdi-close</v-icon>
                    </v-btn>
                  </v-row>
                  <!-- <span class="headline">{{ formTitle }}</span> -->
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <label>University<span class="required">*</span> :</label>
                        <v-text-field :error-messages="error.editedItem.atype_master ? 'Please Enter University' : ''"
                          height="50px" v-model="editedItem.atype_master"
                          onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) ||  event.charCode == 32 || (event.keyCode > 47 && event.keyCode < 58)"
                          persistent-hint :hint="editedIndex == -1
                            ? 'For example ::Savitribai Phule Pune University, Extra-curricular'
                            : 'For example ::Savitribai Phule Pune University'
                            ">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox dense v-model="editedItem.isactive" color="success" label="Active"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="close">Cancel</v-btn>
                  <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                  <!-- :disabled="!valid" -->
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>




      <template v-slot:item.isactive="{ item }">
        <v-switch v-model="item.isactive" @change="activeswitch(item)"
          :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
      </template>

      <template v-slot:item.action="{ item }">
        <div>
          <v-avatar style="" size="25" class="edit-avatar">
            <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
          </v-avatar>
          <v-btn text>
            <v-icon color="red" @click="deletefun(item.id)">
              mdi-delete</v-icon></v-btn>
        </div>
      </template>
      <!-- <template v-slot:item.isactive="{ item }">
            <span v-if="item.isactive">
              <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
            </span>
            <span v-else>
              <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
            </span>
          </template> -->
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    overlay: false,
    valid: false,
    errorMsg: {
      id: false,
    },
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    dialog: false,
    atype_list: null,
    srnolist: [],
    headers: [
      {
        text: "Sr. No",
        sortable: true,
        value: "sr_no",
      },
      {
        text: "University",
        align: "left",
        sortable: true,
        value: "atype_master",
      },
      {
        text: "Active",
        align: "left",
        sortable: true,
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: true,
      },
    ],
    atype_list: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      atype_master: "",
      isactive: true,
    },
    error: {
      editedItem: {

        atype_master: false,

      },
    },
    defaultItem: {
      id: "",
      atype_master: "",
      isactive: true,
    },
  }),

  computed: {

    formTitle() {
      return this.editedIndex === -1 ? "New University" : "Edit University";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },

  // created () {
  //   this.initialize()
  // },
  mounted() {
    this.onLoad();
  },
  methods: {
    hasWhiteSpace(s) {
      var status = false;
      var reWhiteSpace = new RegExp(/^\s+$/);

      // Check for white space
      if (reWhiteSpace.test(s)) {
        alert("Please Check Your Fields For Spaces");
        //   return true;
        return /^\s/.test(s);
      }
      //   return false;
      return /^\s/.test(s);
    },
    initializeErrorMsgFalse() {
      this.errorMsg.id = false;
    },
    onLoad() {
      this.overlay = true;

      axios
        .post("/AdminMaster/addUniversity")
        .then((res) => {
          if (res.data.msg == "200") {
            if ((this.load = true)) {
              this.overlay = false;
              this.atype_list = res.data.atype_list;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          // window.console.log(error);
        })
        .finally(() => {
          // 'use strict';
          var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    editItem(item) {
      this.editedIndex = this.atype_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        if (this.isValidated()) {
          axios
            .post("/AdminMaster/editUniversity", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                Object.assign(
                  this.atype_list[this.editedIndex],
                  this.editedItem
                );
                this.showSnackbar(
                  "#4caf50",
                  "University Updated Successfully..."
                ); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                this.showSnackbar("#b71c1c", "University Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });

          this.close();
        }
      } else {
        if (this.editedItem.atype_master == null || this.editedItem.atype_master == "" || /^[ \d]+$/.test(this.editedItem.atype_master) || this.hasWhiteSpace(this.editedItem.atype_master)) {
          this.showSnackbar("#b71c1c", "Please Enter University ...");
          this.error.editedItem.atype_master = true;
          return;
        }
        axios
          .post("/AdminMaster/saveUniversity", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              this.atype_list.push(this.editedItem);
              this.showSnackbar("#4caf50", "University Added Successfully..."); // show snackbar on success
              this.onLoad();
            } else if (res.data.status.code == "NA") {
              this.showSnackbar("#b71c1c", "University Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    }, // end of save()

    isValidated() {
      if (this.editedItem.id) {
        return true;
      } else {
        if (!this.editedItem.id) {
          this.errorMsg.id = true;
        }

        return false;
      }
    }, // .....end of isValidated()
    deletefun(item) {
      //console.log("a");
      const data = {
        deleteitem: item,
      };
      // console.log("a1");
      if (confirm("Do you really want to delete?")) {
        axios
          .post("/AdminMaster/deleteUniversity", data)
          .then((res) => {
            //console.log("a2");
            if (res.data.msg == "200") {
              //    console.log("success");
              this.showSnackbar("#4caf50", "Deleted Successfully...");
            } else {
              //error
              this.showSnackbar("#b71c1c", res.data.msg);
            }
            this.onLoad();
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    },
    activeswitch(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      //console.log(item.id);

      axios
        .post("/AdminMaster/activeUniversity", data)
        .then((res) => {
          //   console.log("res.data");
          //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.required {
  color: red;
  font-size: 14px;
  font-style: bold;
}

.edit-v-icon:hover {
  color: white;
}
</style>
